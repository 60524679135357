import { inject, Injectable } from '@angular/core';
import {
  collection as collectionFirestore,
  collectionData,
  Firestore,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CollectionReferenceType } from '../types/collection-reference.type';
import { GenericModel } from '../models/generic.model';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private firestore: Firestore = inject(Firestore);

  constructor() {}

  collection(path: string) {
    return collectionFirestore(
      this.firestore,
      `/companies/vinicola-bordignon-mg9s7/${path}`
    );
  }

  collectionData<T extends GenericModel>(collection: CollectionReferenceType) {
    return collectionData<T>(collection, { idField: 'id' }) as Observable<T[]>;
  }
}
